import React, { useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Mobile from '../images/mob.svg'
import NoData from '../images/NO_DATA.svg'
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import Modal from 'react-bootstrap/Modal';
import I21 from '../images/I21.png';
import Loader from '../images/loader3.gif';
import WhiteLoader from '../images/LOADER.gif';

const Settings = () => {
  const [loader, setloader] = useState(true);
  const [content2FA, setcontent2FA] = useState(false);
  const [qrcodeData, setqrcodeData] = useState('');
  const [twoFAmsg, settwoFAmsg] = useState('');
  const [popupmsg, setpopupmsg] = useState('');
  const [otpcode, setotpcode] = useState('');
  const [otpmodal, setotpmodal] = useState(false);
  const [email, setemail] = useState('');
  const [wallet_address, setwallet_address] = useState('');
  const [oldPassword, set_oldPassword] = useState('');
  const [newPassword, set_newPassword] = useState('');
  const [c_newPassword, set_c_newPassword] = useState('');
  const [successMessageChangePassword, setsuccessMessageChangePassword] = useState('');
  const [otpmodal_of_change_password, setotpmodal_of_change_password] = useState(false);
  const [TFACode, setTFACode] = useState('');
  const [change_pass_initial_validation_msg, setchange_pass_initial_validation_msg] = useState('');
  
  useEffect(() => {
    const initialFunction = async() =>{
      var token = localStorage.getItem("token")
      var email = localStorage.getItem("email")
      if(token && email){
        setemail(email)
        setloader(false)
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        const responseWallet = await axios.post('https://services.uax.capital/api/getUserWallet', {
          email: email
        },config);
        setwallet_address(responseWallet.data)
        const responseToken = await axios.post('https://services.uax.capital/api/verifyToken', {
          token: token,
          email: email
        });
        // console.log(responseToken.data.enabledtwofactorauth)
        if(responseToken.data){
          var check2FA = await axios.post("https://services.uax.capital/api/check2FAEnabled",{
            email:email
          })
        }
          if(check2FA.data==true){
            localStorage.setItem('cloudenabledtwofactorauth',true)
            getDetails();
        }
        else if(responseToken.data==="Token Expired"){
          localStorage.clear()
          window.location.href='/login'
        }
      }
      else{
        window.location.href="/login"
      }
    }
    initialFunction()
  }, [])

  const getDetails = () =>{
     if(localStorage.getItem('cloudenabledtwofactorauth').toString()==='true'){
         let encryptedQRdata = localStorage.getItem('cloudqrdata')
         let qrcodeData = CryptoJS.enc.Base64.parse(encryptedQRdata).toString(CryptoJS.enc.Utf8)
         setqrcodeData(qrcodeData)
         setcontent2FA(true)
     }
     else{
      setcontent2FA(false)
     }
  }

  const handleSubmit2FACode = () =>{
    setpopupmsg(<img src={WhiteLoader} style={{width:"3vw"}}/>)
    var emaill = localStorage.getItem("email")
    if(emaill){
    var encrypted = CryptoJS.AES.encrypt(emaill,"key")
    var secret = localStorage.getItem("cloudsecret")
    axios.post("https://services.uax.capital/api/confirmEnableTwoFA",{encrypt1:encrypted.toString(),token:otpcode,secret:secret})
    .then(res=>{

      if(res.data!="2FA Verfication failed,you can't enable 2FA"){
        localStorage.setItem('cloudenabledtwofactorauth',true);
        var a = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(res.data))
        localStorage.setItem("cloudqrdata",a);
        setpopupmsg('2FA Enabled Successfully')
        getDetails();
        setTimeout(()=>{
          setotpmodal(false)
        },2000)
      }
      else{
        setpopupmsg(res.data)
      }
    })
    .catch(err=>{
      console.log(err)
      setpopupmsg("Something went wrong , try again later.")
    })
    }
    else{
      setpopupmsg("Something went wrong , try again later.")
    }
  }

  const enableTwoFA = ()=>{
    settwoFAmsg( <img src={WhiteLoader} style={{width:"3vw"}}/>)
     var emaill = localStorage.getItem("email")
     if(emaill){
     var encrypted = CryptoJS.AES.encrypt(emaill,"key")
     axios.post("https://services.uax.capital/api/enableTwoFA",{encrypt1:encrypted.toString()})
     .then(res=>{
          // localStorage.setItem('cloudenabledtwofactorauth',true);
         var a = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(res.data))
         let qrcodeData = CryptoJS.enc.Base64.parse(a).toString(CryptoJS.enc.Utf8)
          // localStorage.setItem("cloudqrdata",a);
          setotpmodal(true)
          setqrcodeData(qrcodeData)
          settwoFAmsg('')
          // console.log()
        //  getDetails();
     })
     .catch(err=>{
      settwoFAmsg("Something went wrong , try again later.")
     })
     }
     else{
      settwoFAmsg("Something went wrong , try again later.")
     }
 }

 const handleClose = () => {
  setotpmodal(false)
  setpopupmsg('')
  settwoFAmsg('')
  setotpmodal_of_change_password(false)
  setchange_pass_initial_validation_msg('')
  setsuccessMessageChangePassword('')
};

const submitChnagePassword = async() =>{
  let secret =  localStorage.getItem('cloudsecret');
  setsuccessMessageChangePassword(
    <img src={Loader} style={{width:"3vw"}}/>
  )
  if(!secret){
    setsuccessMessageChangePassword("Invalid secret code")
  }
  else{
    if(oldPassword.length>0 && newPassword.length>0 && TFACode.length>0){
      if(newPassword===c_newPassword){
        const encrypt1 = CryptoJS.AES.encrypt(email, 'key').toString();
        const encrypt2 = CryptoJS.AES.encrypt(oldPassword, 'key').toString();
        const change_pass = await axios.post("https://services.uax.capital/api/AccChangePass",{
          encrypt1:encrypt1,
          encrypt2:encrypt2,
          newPass:newPassword,
          token:TFACode,
          secret:secret
        })
        setsuccessMessageChangePassword(change_pass.data)
        if(change_pass.data==='Password changed successfully'){
          setTimeout(()=>{
            localStorage.clear();
            window.location.href = "/login"
          },2000)
        }
      }
      else{
        setsuccessMessageChangePassword("Password doesn't match")
      }
    }
    else{
      setsuccessMessageChangePassword("Old password, new password and 2FA OTP are required")
    }
  }
}

const handleChnagePassword = async() =>{
  setchange_pass_initial_validation_msg(
    <img src={Loader} style={{width:"3vw"}}/>
  )
    if(oldPassword.length>0 && newPassword.length>0){
      if(newPassword===c_newPassword){
        setotpmodal_of_change_password(true)
        setchange_pass_initial_validation_msg('')
      }
      else{
        setchange_pass_initial_validation_msg("Password doesn't match")
      }
    }
    else{
      setchange_pass_initial_validation_msg("Old password, new password and 2FA OTP are required")
    }
}

  return(
  <>
  {/* {loader?
    <div className='' style={{height:"80vh",position:"relative",backgroundColor:"#000"}}>
      <center style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}>
      <i className="fa fa-circle-o-notch fa-spin" style={{ fontSize: "30px" }}></i>
      </center>
    </div>
    : */}
    <div className="container" style={{ minHeight: "100vh" }}>
      <div className='mt-3'>
        <div className='dashboard_box_001____ px-4 mb-4'>
          <div className='my-5 for_device_difference____mx_5____'>
            <div className='mt-4'>
              <div className="row">
                <div className="col-lg-3 col-md-12 mt-3 nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{ borderRight: "1px solid #2b272a" }}>
                  <button className="nav-link" id="v-pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-disabled" type="button" role="tab" aria-controls="v-pills-disabled" aria-selected="false" disabled style={{ color: "#bab8b8" }}>General</button>
                  <button className="nav-link active customized_nav_pills____" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Basic Information</button>
                  <button className="nav-link customized_nav_pills____" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Wallet Settings</button>
                  <button className="nav-link customized_nav_pills____for_disabled" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false" disabled>Developer Settings</button>
                </div>
                <div className="col-lg-9 col-md-12 mt-3 tab-content" id="v-pills-tabContent">
                  <div className="tab-pane fade show active px-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                    <p className='' style={{ fontWeight: "900", fontSize: "20px" }}>Basic Information</p>
                    <form className="g-3 mt-5">
                      <div className='row'>
                        <div className="col-md-6">
                          <label htmlFor="inputFullName" className="form-label">Email</label>
                          <div className="input-group">
                            <input value={email} type="text" className="form-control customized_input_box_basic_settings_____" id="inputFullName" disabled/>
                            {/* <span className="input-group-text" style={{backgroundColor:"#403242",border:"none",color:"#fff"}}>
                              <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                            </span> */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="inputDisplayName" className="form-label">Wallet Address</label>
                          <div className="input-group">
                            <input type="text" value={wallet_address} className="form-control customized_input_box_basic_settings_____" id="inputDisplayName" disabled/>
                            {/* <span className="input-group-text" style={{backgroundColor:"#403242",border:"none",color:"#fff"}}>
                              <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                            </span> */}
                          </div>
                        </div>
                      </div>

                      <div className='mt-5'>
                      <p className='' style={{ fontWeight: "900", fontSize: "20px" }}>Change Password</p>
                      <div className='row'>
                        <div className="col-md-6">
                          <label htmlFor="inputPassword" className="form-label">Old Password</label>
                          <div className="input-group">
                            <input type="password" onChange={(e)=>set_oldPassword(e.target.value)} className="form-control customized_input_box_basic_settings_____" id="inputPassword" />
                            <span className="input-group-text" style={{backgroundColor:"#403242",border:"none",color:"#fff"}}>
                              <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="inputEmail" className="form-label">New Password</label>
                          <div className="input-group">
                            <input type="password" onChange={(e)=>set_newPassword(e.target.value)} className="form-control customized_input_box_basic_settings_____" id="inputEmail" />
                            <span className="input-group-text" style={{backgroundColor:"#403242",border:"none",color:"#fff"}}>
                              <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='row mt-2'>
                       <div className="col-md-6">
                       <label htmlFor="inputEmail" className="form-label">Confirm New Password</label>
                          <div className="input-group">
                            <input type="password" onChange={(e)=>set_c_newPassword(e.target.value)} className="form-control customized_input_box_basic_settings_____" id="inputEmail" />
                            <span className="input-group-text" style={{backgroundColor:"#403242",border:"none",color:"#fff"}}>
                              <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      </div>
                      <Button
                      className="w-100 primary_btnn___ mt-3"
                      variant="primary"
                      type="button"
                      onClick={handleChnagePassword}
                      >
                      Change Password
                    </Button>
                    {change_pass_initial_validation_msg && (
                    <div className="alert alert-success mt-3 text-center" role="alert">
                      {change_pass_initial_validation_msg}
                    </div>
                  )}
                    </form>
                    <p className='mt-5' style={{ fontWeight: "900", fontSize: "20px" }}>Email Subscription</p>
                    <div className='settings_box_0002____ d-flex justify-content-between align-items-center'>
                        <span>
                        Signup to receive UAX emails and get exclusive content, events and more!
                        </span>
                        <Button
                          style={{
                            // marginLeft: "10px",
                            backgroundColor: "transparent",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            border: "1px solid #5b505c"
                          }}>
                          Open
                        </Button>
                    </div>
                  </div>
                  <div className="tab-pane fade px-2" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0">
                  <p className='' style={{ fontWeight: "900", fontSize: "20px" }}>Account Security</p>
                  <div className='settings_box_0003____ mt-5'>
                    
                
                  <div className='d-flex justify-content-between align-items-center'>
                   
                    {content2FA?
                      <>
                      <center style={{width:"100%"}}>
                      <p className='' style={{ fontWeight: "900", fontSize: "20px" }}>2FA Code</p>
                      <img src={qrcodeData} style={{height:'25vh'}}/>
                      </center>
                      </>
                      :
                      <>
                       <span>
                        2-Factor Authentication
                        </span>
                        <Button
                        onClick={enableTwoFA}
                          style={{
                            marginLeft: "10px",
                            backgroundColor: "#c006de",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            border: "1px solid #5b505c"
                          }}>
                          Enable
                        </Button>
                      </>
                    }
                    
                       
                    </div>
                    {twoFAmsg?
                     <center className='my-4'>
                     <span>{twoFAmsg}</span>
                     </center>
                     :
                     ''
                    }
                   
                        </div>
                    <p className='mt-5' style={{ fontWeight: "900", fontSize: "20px" }}>Wallet Settings</p>
                    <span className='' style={{ fontWeight: "900", fontSize: "15px" }}>Authorized Apps</span><br/>
                    <small style={{color:"#a8a8a8"}}>List of dApps got permission granted via UAXJS</small>
                    <div className='settings_box_0003____ mt-4'>
                    <div className='row mt-3 cusomized_box_from_settings_wallet_mx_5'>
                        <div className='col-md-6 col-sm-12'>
                            <img src={Mobile} className='w-100'/>
                        </div>
                        <div className='col-md-6 col-sm-12 my-3'>
                            <div className='parent' style={{height:"100%"}}>
                                <div className='child' style={{position:"relative"}}>
                                    <h5>
                                    Create your UAX account to start using <span style={{color:"#c006de"}}>wallet</span> features
                                    </h5>
                                    <Button
                                        style={{fontWeight:"900",backgroundColor:"grey"}}
                                        className="primary_btnn___ mt-3"
                                        variant="primary"
                                        type="submit"
                                        disabled
                                        >
                                         Create Now
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                  </div>
                  <div className="tab-pane fade px-2" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab" tabIndex="0">
                  <p className='' style={{ fontWeight: "900", fontSize: "20px" }}>Developer Settings</p>
                  <div className='settings_box_0003____ mt-5 d-flex justify-content-between align-items-center'>
                        <span>
                        API Key
                        <br/>
                        <span>Use UAX API to streamline and automate some actions</span>
                        </span>
                        <Button
                          style={{
                            marginLeft: "10px",
                            backgroundColor: "#c006de",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            border: "1px solid #5b505c"
                          }}>
                          Generate
                        </Button>
                    </div>
                    <div className='settings_box_0003____ mt-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span>
                        OAuth
                        <br/>
                        <span>UAX OAuth</span>
                        </span>
                        <Button
                          style={{
                            marginLeft: "10px",
                            backgroundColor: "#c006de",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            border: "1px solid #5b505c"
                          }}>
                          Request
                        </Button>
                    </div>
                    <div className='mt-4 px-3'>
                        <Table responsive style={{}}>
                            <tbody>
                            <th className='table_header_class_dev_settings____'>ClientId</th>
                            <th className='table_header_class_dev_settings____'>dApp Name</th>
                            <th className='table_header_class_dev_settings____'>Redirect URL</th>
                            <th className='table_header_class_dev_settings____'>Status</th>
                            <th className='table_header_class_dev_settings____'>Action</th>
                            </tbody>
                        </Table>
                        <div className='text-center pb-3'>
                          <img src={NoData}/>
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
{/* } */}
    <Modal show={otpmodal} onHide={handleClose} size="" style={{zIndex:"99999999999"}}>
          <Modal.Body style={{ padding: '5%', backgroundColor: '#1a181b', border: 'none',color:"#fff" }}>
            <center>
              <img src={I21} style={{ height: '15vh' }} alt="Authenticator App" />
              <p style={{ fontWeight: '700', fontSize: '20px', marginTop: '15px' }}>Activate the authenticator app</p>
              <small>
                You'll need to install a two-factor authentication application on your smartphone/tablet such as Google
                Authenticator (Android/phone) or Authenticator (Windows phone)
              </small>
              <br />
            </center>
            <div style={{ marginTop: '30px' }}>
              <small style={{ fontWeight: '600' }}>1. Configure the app</small>
              <br />
              <small>
                Open your two-factor authentication application and add your UAX account by scanning the QR code.
              </small>
              <center className='my-5'>
                <img src={qrcodeData} style={{ height: '25vh' }} alt="QR Code" />
                {/* <br/>
                <small>If you can't use a QR code. <Link to="#" style={{color:'#532DA1',fontWeight:'600',textDecoration:'none'}}>enter this text code.</Link></small> */}
              </center>
              <center>
              <span>Enter OTP</span>
               <div className="input-group mb-1 mt-3" style={{ width: '50%' }}>
               <input type="text" onChange={(e)=>setotpcode(e.target.value)} className="form-control" placeholder='0 0 0 0 0 0' style={{color:"#000"}} id="inputFullName" />
              </div>
          <button
            onClick={handleSubmit2FACode}
            style={{
              color: 'white',
              backgroundColor: '#c006df',
              border: 'none',
              minHeight: '5.5vh',
              minWidth: '9rem',
              borderRadius: '.25rem',
              fontWeight: '600',
              marginTop: '20px'
            }}
          >
            Submit
          </button>
          <p style={{ marginTop: '10px' }}>{popupmsg}</p>
          </center>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={otpmodal_of_change_password} onHide={handleClose}>
      <Modal.Body style={{ padding: '5%', border: 'none',color:"#fff",backgroundColor: '#1a181b', }}>
        <center>
          <img src={I21} style={{ height: '15vh' }} alt="I21" />
          <p style={{ fontWeight: '700', fontSize: '20px', marginTop: '15px' }}>
            Change Your Account Password
          </p>
          <span>
            Protecting your account is our top priority. Please confirm your
            account by entering the authorization code
          </span>
          <br />
          <div className="input-group mb-1 mt-3" style={{ width: '50%' }}>
            <input
              type="number"
              name="TFACode"
              onChange={(e) => setTFACode(e.target.value)}
              className="form-control text-center"
              placeholder="3 3 3 3"
              style={{borderRadius:"5px"}}
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                id="basic-addon2"
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  padding: '0px',
                  cursor: 'pointer',
                  marginLeft: '7px'
                }}
              ></span>
            </div>
          </div>
          <button
            onClick={submitChnagePassword}
            style={{
              color: 'white',
              backgroundColor: '#c006de',
              border: 'none',
              minHeight: '5.5vh',
              minWidth: '9rem',
              borderRadius: '.25rem',
              fontWeight: '600',
              marginTop: '20px'
            }}
          >
            Submit
          </button>
        </center>
                  {successMessageChangePassword && (
                    <div className="alert alert-success mt-3 text-center" role="alert">
                      {successMessageChangePassword}
                    </div>
                  )}
      </Modal.Body>
    </Modal>
  </>
);
}

export default Settings;
